<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col sm="6" md="3">
          <b-form-group>
            <b-form-select
              v-model="selectedFilters.year"
              :options="selectFields.year"
              @input="getBestlist"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="3">
          <b-form-group>
            <b-form-select
              v-model="selectedFilters.category"
              :options="selectFields.category"
              @change="setCategoryDisciplines"
              @input="getBestlist"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="3">
          <b-form-group>
            <b-form-select
              v-model="selectedFilters.canton"
              :options="selectFields.canton"
              @input="getBestlist"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="3">
          <b-form-group>
            <b-form-select
              v-model="selectedFilters.discipline"
              :options="selectFields.discipline"
              @input="getBestlist"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" class="mb-3" v-if="selectedFilters.category == 0">
      <span class="text-danger">{{ $t('bestenliste.warning_kategorie_wählen') }}</span>
    </b-col>
    <b-col cols="12" class="mb-3" v-if="selectedFilters.category != 0 && selectedFilters.discipline == 0">
      <span class="text-danger">{{ $t('bestenliste.warning_disziplin_wählen') }}</span>
    </b-col>
    <b-col v-if="selectedFilters.year != this.getCurrentYear() || selectedFilters.canton != 0 || selectedFilters.category != 0 || selectedFilters.discipline != 0" cols="12" class="mb-3">
      <b-button v-on:click="resetFilter" size="l"
        >{{ $t('bestenliste.label_filter_zuruecksetzen') }}</b-button
      >
    </b-col>
    <b-col cols="12" v-if="results.length > 0">
      <span class="mr-1 text-danger">{{ $t('bestenliste.label_zeige') }}:</span>
      <span :class="{'mr-1': true, 'font-weight-bold': selectedFilters.maxRow == 50}"><b-link @click="setMaxRow(50)">50</b-link></span>
      <span :class="{'mr-1': true, 'font-weight-bold': selectedFilters.maxRow == 100}"><b-link @click="setMaxRow(100)">100</b-link></span>
      <span :class="{'mr-1': true, 'font-weight-bold': selectedFilters.maxRow == 500}"><b-link @click="setMaxRow(500)">500</b-link></span>
      <span :class="{'mr-1': true, 'font-weight-bold': selectedFilters.maxRow == 999999}"><b-link @click="setMaxRow(999999)">alle</b-link></span>
    </b-col>
    <b-col v-if="results.length > 0" cols="12" class="d-none d-md-block">
      <b-table
        striped
        hover
        responsive
        :items="results"
        :fields="resultFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :primary-key="results.kidName"
      >
      </b-table>
    </b-col>
    <b-col
      v-if="results.length > 0"
      cols="12"
      class="d-block d-md-none"
    >
      <b-row v-for="result in results" :key="result.kidName">
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col sm="10">
          <b-row>
            <b-col cols="12 mb-2">
              <span class="d-block">{{ $t('bestenliste.label_rang') }}: {{ result.place }}#</span>
              <span class="d-block">{{ $t('bestenliste.label_resultat') }}: {{ result.timeResult }}</span>
            </b-col>
            <b-col cols="12 mb-2">
              <span v-if="result.kidName != ''" class="font-weight-bold d-block">{{ result.kidName }} <span v-if="result.kidQualificationCanton != ''" class="font-weight-normal">({{ result.kidQualificationCanton }})</span></span>
              <span v-if="result.kidAccountName != ''" class="d-block">{{ result.kidAccountName }}</span>
            </b-col>
            <b-col cols="12 mb-2">
              <span v-if="result.nwpName != ''" class="d-inline-block">{{ result.nwpName }}</span><span v-if="result.venue != ''" class="d-inline-block">, {{ result.venue }}</span>
              <span v-if="result.startDate != ''" class="d-block">{{ formatDate(result.startDate) }}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-col v-if="results.length == 0 && selectedFilters.category != 0 && selectedFilters.discipline != 0 && !displayPageLoader">
      <b-alert variant="info" show>
        {{ $t('bestenliste.label_keine_resultate_vorhanden') }}
      </b-alert>
    </b-col>
    <div
      v-if="displayPageLoader"
      id="pageLoader"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </b-row>
</template>

<script>
export default {
  data() {
    var currentYear = this.getCurrentYear();
    const yearDefaultElement = [{ value: 0, text: this.$t('bestenliste.label_alle_jahre') }];
    const cantonDefaultElement = [{ value: 0, text: this.$t('bestenliste.label_ganze_schweiz') }];
    const categoryDefaultElement = [{ value: 0, text: this.$t('bestenliste.label_kategorie_wählen') }];
    const disciplineDefaultElement = [{ value: 0, text: this.$t('bestenliste.label_disziplin_wählen') }];

    return {
      sortBy: 'timeResult',
      sortDesc: false,
      selectFields: {
        year: yearDefaultElement,
        canton: cantonDefaultElement,
        category: categoryDefaultElement,
        discipline: disciplineDefaultElement,
        yearDefaultElement: yearDefaultElement,
        cantonDefaultElement: cantonDefaultElement,
        categoryDefaultElement: categoryDefaultElement,
        disciplineDefaultElement: disciplineDefaultElement,
      },
      selectedFilters: {
        year: currentYear,
        canton: 0,
        category: 0,
        discipline: 0,
        maxRow: 50,
        showResultsAsBestlist: true,
      },
      resultFields: [
        {
          key: "place",
          label: this.$t('bestenliste.label_position'),
          sortable: true,
        },
        {
          key: "timeResult",
          label: this.$t('bestenliste.label_resultat'),
          sortable: true,
          formatter: "formatTimeResult"
        },
        {
          key: "kidName",
          label: this.$t('bestenliste.label_name'),
          sortable: true,
        },
        {
          key: "ageGroup",
          label: this.$t('bestenliste.label_jahrgang'),
          sortable: true,
        },
        {
          key: "kidAccountName",
          label: this.$t('bestenliste.label_verein'),
          sortable: true,
        },
        {
          key: "kidQualificationCanton",
          label: this.$t('bestenliste.label_qualification_kanton'),
          sortable: true,
        },
        {
          key: "nwpName",
          label: this.$t('bestenliste.label_wettkampfname'),
          sortable: true,
        },
        {
          key: "venue",
          label: this.$t('bestenliste.label_ort'),
          sortable: true,
        },
        {
          key: "startDate",
          label: this.$t('bestenliste.label_datum'),
          sortable: true,
          formatter: "formatDate",
        },
      ],
      resultCategories: [],
      results: [],
      displayPageLoader: false,
    };
  },
  created() {
    this.getBestlistCategories();
    this.getCantonsList();
    this.setFilterableYears();
  },
  methods: {
    mapResults(data) {
      var tmp = [];
      data.forEach((event) => {
        let tmpEvent = event;

        tmpEvent.nwpDiscipline = this.$t(event.nwpDiscipline);
        tmpEvent.kidCanton = this.$t(event.kidCanton);
        tmpEvent.kidQualificationCanton = this.$t(event.kidQualificationCanton);

        tmp.push(tmpEvent);
      });
      return tmp;
    },
    getBestlist() {
      // Display loading-animation
      this.displayPageLoader = true;

      // Check if all nessessary filters are set
      if (this.selectedFilters.category == 0 || this.selectedFilters.discipline == 0) {
        this.results = [];
        
        // Hide loading-animation
        this.displayPageLoader = false;

        return;
      }
        //canton: this.selectedFilters.canton != 0 ? this.selectedFilters.canton : null,

      const filter = {
        year: this.selectedFilters.year != 0 ? this.selectedFilters.year : null,
        qualificationCanton: this.selectedFilters.canton != 0 ? this.selectedFilters.canton : null,
        category: this.selectedFilters.category != 0 ? this.selectedFilters.category : null,
        discipline: this.selectedFilters.discipline != 0 ? this.selectedFilters.discipline : null,
        showResultsAsBestlist: this.selectedFilters.showResultsAsBestlist != 0 ? this.selectedFilters.showResultsAsBestlist : true,
        maxRow: this.selectedFilters.maxRow != 0 ? this.selectedFilters.maxRow : 30,
      };
      this.$store
        .dispatch("wettkampf/getBestlist", filter)
        .then((resp) => {
          this.results = this.mapResults(resp.data);

          // Sortiere Resultate nach Zeit
          this.results.sort((a, b) => {
            return a.timeResult == b.timeResult ? 0 : (a.timeResult < b.timeResult ? -1 : 1);
          })

          // Setze Rang anhand der Reihenfolge (selber Rang, wenn selbe Zeit)
          var previousTime = 0;
          var currentPlace = 1;
          for (let index = 0; index < this.results.length; index++) {
            const currentTime = this.results[index].timeResult;
            if (currentTime === previousTime) {
              this.results[index].place = currentPlace - 1;
            } else {
              this.results[index].place = currentPlace;
            }
            previousTime = currentTime;
            currentPlace++;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          // Hide loading-animation
          this.displayPageLoader = false;
        });
    },
    getBestlistCategories() {
      this.$store
        .dispatch("wettkampf/getBestlistCategories")
        .then((resp) => {
          this.resultCategories = resp.data;

          // Prepare category dropdown
          this.selectFields.category = Object.keys(this.resultCategories);
          this.selectFields.category = this.selectFields.category.map((cat) => {
            var rObj = { value: "", text: "" };
            rObj.value = cat;
            rObj.text = this.$t(cat);
            return rObj;
          });
          this.selectFields.category = [].concat(
            this.selectFields.categoryDefaultElement,
            this.selectFields.category
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    mapLov(arr) {
      return arr.map((obj) => {
        var rObj = { value: "", text: "" };
        rObj.value = obj.lic;
        rObj.text = this.$t(obj.lic);
        return rObj;
      });
    },
    filterLovList(data, filter = "") {
      var returnData = [];
      data.forEach((obj) => {
        var objArr = obj.lic.split(".");
        if (objArr[objArr.length - 1].startsWith(filter)) {
          returnData.push(obj);
        }
      });
      return returnData;
    },
    getCantonsList() {
      this.$store
        .dispatch("lov/getCantonList")
        .then((resp) => {
          this.selectFields.canton = [].concat(
            this.selectFields.cantonDefaultElement,
            this.mapLov(resp.data)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCurrentYear() {
      var current = new Date();
      return current.getFullYear();
    },
    setMaxRow(value) {
      this.selectedFilters.maxRow = value;
      this.getBestlist();
    },
    setFilterableYears() {
      const firstYear = 2023;
      var currentYear = this.getCurrentYear();
      var yearList = [];

      for (let loopYear = currentYear; loopYear >= firstYear; loopYear--) {
        var rObj = { value: loopYear, text: loopYear };
        yearList.push(rObj);
      }
      
      this.selectFields.year = [].concat(
        this.selectFields.yearDefaultElement,
        yearList
      );
    },
    setCategoryDisciplines(value) {
      // Check if category is set
      if (!value) {
        this.selectFields.discipline = this.selectFields.disciplineDefaultElement;
        this.selectedFilters.discipline = 0;
        return;
      };

      // Reset discipline dropdown
      this.selectedFilters.discipline = 0;

      var disciplines = this.resultCategories[value];
      disciplines = disciplines.map((discipline) => {
        var rObj = { value: "", text: "" };
        rObj.value = discipline;
        rObj.text = this.$t(discipline);
        return rObj;
      });

      // Filtere alle Disziplinen heraus, ausser 50m, 60m und 80m
      disciplines = disciplines.filter(item => {
        return (item.value == 'Lov.Sat.Discipline.10' || item.value == 'Lov.Sat.Discipline.30' || item.value == 'Lov.Sat.Discipline.35')
      })

      this.selectFields.discipline = [].concat(
        this.selectFields.disciplineDefaultElement,
        disciplines
      );

      // Preselect value in DropDown, if only one option is available
      // Then trigger filtering
      if (this.selectFields.discipline.length == 2) {
        this.selectedFilters.discipline = this.selectFields.discipline[1].value;
        this.getBestlist();
      }
    },
    formatTimeResult(value) {
      return value.toFixed(2);
    },
    formatDate(dateStr) {
      var date = new Date(dateStr);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      return date.toLocaleDateString('de', options);
    },
    resetFilter() {
      this.selectedFilters.year = this.getCurrentYear();
      this.selectedFilters.canton = 0;
      this.selectedFilters.category = 0;
      this.selectedFilters.discipline = 0;
      this.selectFields.discipline = this.selectFields.disciplineDefaultElement;

      this.results = [];
    },
  },
};
</script>

<style lang="scss" scoped>
#pageLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(239, 239, 239, 0.6);
}
</style>